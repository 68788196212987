/*********
Theme: Min Nav
Description: Min Nav Two Col Dashboard 
version: 1.0v
********/

body {
  background: #f8f9fb;
  font-family: "Inter", sans-serif;
}
a {
  color: #68a2b9;
}
.logo {
  margin-bottom: 37px;
}

.checkbox-password {
  margin-bottom: 39px;
  border-radius: 3px !important;
  margin-top: 0px;
  height: 20px;
  width: 20px;
}
.form-check-remember {
  margin: 1px 0px 0px -9px;
}
.back-home {
  font-size: 14px;
  font-weight: 500;
}
.signup-text {
  font-size: 14px;
}

/*******btn*********/
.btn {
  padding: 0.5rem 1.25rem;
  border-radius: 0.375rem;
  font-weight: 500;
}
.btn-primary {
  background-color: #68a2b9 !important;
  border-color: #68a2b9;
}
.btn-primary:hover {
  background-color: #72bad6;
  border-color: #72bad6;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle,
.btn-primary:focus {
  background-color: #72bad6;
  border-color: #72bad6;
}

.remove-image {
  color: #8a93a7;
}
.btn-icon i {
  vertical-align: middle;
  margin-right: 8px;
  font-size: 14px;
}
.btn-light {
  background-color: #ebeef6;
  border-color: #ebeef6;
}
.search-ticket {
  border: 2px solid #eceff7;
  width: 174px;
  float: right;
}
.search-transaction {
  padding: 5px;

  border: 2px solid #eceff7;
}
::-webkit-input-placeholder {
  font-size: 12px;
}
::-moz-placeholder {
  font-size: 12px;
}
:-ms-input-placeholder {
  font-size: 12px;
}
::placeholder {
  font-size: 12px;
}
.sharp.btn-xs {
  padding: 3px;
  width: 26px;
  height: 26px;
  min-width: 26px;
  min-height: 26px;
  line-height: 20px;
}
.sharp.btn-xs i {
  font-size: 12px;
}
.shadow.btn-danger {
  -webkit-box-shadow: 0 5px 15px 0 rgb(242 87 103 / 20%) !important;
  box-shadow: 0 5px 15px 0 rgb(242 87 103 / 20%) !important;
}
.shadow.btn-primary {
  -webkit-box-shadow: 0 5px 15px 0 rgb(58 122 254 / 20%) !important;
  box-shadow: 0 5px 15px 0 rgb(58 122 254 / 20%) !important;
}

.light.btn-primary {
  background-color: #ecf2ff;
  border-color: #ecf2ff;
  color: #3a7afe;
}
/********login page**********/
.log-banner {
  background-image: url("./images/log-banner.png");
  background-repeat: no-repeat;
  height: 100vh;
  background-size: cover;
  background-position: center;
  width: 100%;
}
.log-banner .overlay {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100vh;
  display: block;
  background: rgb(0 0 0 / 60%);
}

.card-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 3.5rem);
  transform: none;
  max-width: 500px;
  margin: 1.75rem auto;
  position: relative;
  width: auto;
}

.log-card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  padding: 49px 100px;
  border: none;
  border-radius: 4px;
  outline: 0;
  border: 1px solid #979797;
}

.log-card .brand {
  text-align: center;
  margin: 0px auto;
  font-size: 72px;
  color: #68a2b9;
  line-height: 72px;
}
.log-card a {
  text-decoration: none;
}
.log-card form {
  margin-bottom: 20px;
}
.log-card form .form-group {
  margin-bottom: 24px;
}
.log-card form .form-group a {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #68a2b9;
  float: right;
}
.log-card form .form-label {
  font-size: 12px;
  color: #8a93a7;
  line-height: 12px;
  display: block;
  margin-bottom: 8px;
  text-align: left;
}
.log-card form .form-control {
  padding: 14px 16px;
  border: 1px solid #e1e5ee;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
}
.form-control:focus {
  box-shadow: 0 0 0 0.05rem rgb(104 162 185);
  border: 1px solid rgb(104 162 185) !important;
}
.select-box .css-yk16xz-control {
  height: 32px !important;
  border: 2px solid #eceff7 !important;
}
.filter-select-box .css-yk16xz-control {
  height: 48px !important;
  border: 2px solid #eceff7 !important;
}

.filter-select-box .css-1pahdxg-control:focus,
.filter-select-box .css-1pahdxg-control:active {
  border-color: #86b7fe !important;
  height: 44px !important;
}
.btn-lg {
  height: 48px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
}
.log-card form .form-group .form-check {
  font-size: 14px;
  line-height: 18px;
  color: #58627a;
}
/********sidebar**********/
.main-sidebar {
  width: 60px;
  position: fixed;
  left: 0px;
  top: 0px;
  background: #131920;
  height: 100%;
  z-index: 9;
}
.main-sidebar .sidebar-brand {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 70px;
}
.main-sidebar .sidebar-brand .bi {
  font-size: 30px;
  color: #fff;
}
.main-sidebar .sidebar-inner {
  height: calc(100% - 60px);
  width: 100%;
  position: relative;
}
.main-sidebar .sidebar-inner .nav .nav-item .nav-link {
  color: #ccc;
  position: relative;
  padding: 15px 0px;
  text-align: center;
}
.main-sidebar .sidebar-inner .nav .nav-item .nav-link .svg-icon {
  fill: #8a99a3;
  stroke: #8a99a3;
}
.main-sidebar .sidebar-inner .nav .nav-item .nav-link .svg-icon-2 {
  stroke: #8a99a3;
}

.main-sidebar .sidebar-inner .nav .nav-item .nav-link:hover .svg-icon {
  fill: #68a2b9;
  stroke: #68a2b9;
}
.main-sidebar .sidebar-inner .nav .nav-item .nav-link:hover .svg-icon-2 {
  stroke: #68a2b9;
}

.main-sidebar .sidebar-inner .nav .nav-item .nav-link.active:before {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 2px;
  height: 100%;
  background: #68a2b9;
  content: "";
}
.main-sidebar .sidebar-inner .nav .nav-item .nav-link.active {
  background: transparent;
  color: #68a2b9;
}
.main-sidebar .sidebar-inner .nav .nav-item .nav-link.active .svg-icon {
  fill: #68a2b9;
  stroke: #68a2b9;
}
.main-sidebar .sidebar-inner .nav .nav-item .nav-link.active .svg-icon-2 {
  stroke: #131920;
  fill: #68a2b9;
}

.main-sidebar .sidebar-inner .bottom-menu {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 60px;
}

.main-sidebar .sidebar-inner .bottom-menu .nav {
  display: block;
}

.main-sidebar .sidebar-inner .bottom-menu .nav .nav-item .nav-link {
  color: #ccc;
  position: relative;
}
.main-sidebar .sidebar-inner .bottom-menu .nav .nav-item .nav-link .dot {
  background: #68a2b9;
  height: 7px;
  width: 7px;
  border: 1px solid #131920;
  display: block;
  border-radius: 50%;
  position: absolute;
  left: 30px;
  top: 20px;
}
.main-sidebar .sidebar-inner .bottom-menu .nav .nav-item .nav-link img {
  display: block;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  margin: 0px auto;
}
/********content**********/
.title-row {
  margin-bottom: 24px;
}
.title-row h1 {
  font-size: 24px;
  font-weight: 600;
  color: #232a39;
  line-height: 32px;
  margin-bottom: 8px;
}
.title-row p {
  color: #58627a;
  font-size: 14px;
  line-height: 17px;
  margin: 0px;
}
.filter-row {
  margin-bottom: 24px;
}

.filter-row .dropdown {
  display: inline-block;
  margin-right: 15px;
}
.filter-row .dropdown > .btn-light {
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  border: none;
  padding: 6px 8px;
}

.filter-row .dropdown > .btn-light:active,
.filter-row .dropdown > .btn-light:focus,
.btn-light:focus,
.btn-light:active,
.filter-row .btn-light:active,
.filter-row .btn-light:focus {
  border: 1px solid #68a2b9;
  border-radius: 4px;
  background-color: #eff5f8 !important;
  box-shadow: 0 0 0 -0.75rem rgb(211 212 213 / 50%);
}
.filter-row .btn-light {
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  border: none;
  padding: 6px 8px;
  font-size: 14px;
  color: #58627a;
}
.card-order {
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%);
}

.card-info {
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  min-height: 110px;
  border: none;
  padding: 20px 30px 20px 64px;
  margin-bottom: 30px;
  position: relative;
}
.card-info .card-body {
  padding: 0px;
}
.card-info .card-title {
  font-size: 14px;
  font-weight: 500;
  color: #58627a;
  line-height: 17px;
  display: block;
  margin-bottom: 12px;
}
.card-info .card-number {
  color: #232a39;
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  margin: 0px;
}
.card-info .card-icon {
  position: absolute;
  left: 30px;
  top: 20px;
}

.card-table {
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  min-height: 110px;
  border: none;
  padding: 20px 30px;
  margin-bottom: 30px;
  position: relative;
}
.card-table .card-body {
  padding: 0px;
  margin-bottom: 13px;
}
.card-table .card-head {
  margin-bottom: 13px;
}
.card-table .card-head h3 {
  color: #232a39;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin: 7px 0px 14px 0px;
}
.card-table .card-head .form-select {
  border: 1px solid #eceff7;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  color: #58627a;
}
.table-section {
  overflow-x: auto;
}

.content {
  margin-left: 60px;
}
.content.profile-list-content {
  margin-left: 337px;
}
.profile-list h3 {
  color: #232a39;
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  padding: 14px 30px;
}
.content.data-list-content {
  margin-left: 361px;
}
.is-open .content.data-list-content {
  margin-left: 60px;
}
.content.data-list-content .container-fluid {
  padding: 0px;
}
.content .container-fluid {
  padding: 30px;
}

.is-open .data-list {
  display: none;
}

.toggle-btns {
  position: absolute;
  left: -25px;
  background: none;
  border: none;
  padding: 0px;
  height: 24px;
  width: 24px;
  top: 18px;
}
.toggle-btns img {
  display: inline-block;
  vertical-align: baseline;
}
.is-open .toggle-btns {
  transform: rotate(180deg);
  left: 0px;
  z-index: 1111;
}

.data-label {
  color: #8a93a7;
  font-family: Inter;
  font-size: 14px;
  line-height: 18px;
  display: block;
  margin-bottom: 8px;
}
.data-data {
  color: #232a39;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  display: block;
}
.table-style {
  border: none;
}
.table-style thead tr th {
  background-color: #f7f9fc;
  box-shadow: inset 0 -1px 0 0 #eceff7;
  font-size: 14px;
  color: #8a93a7;
  line-height: 16px;
  font-weight: normal;
  padding: 12px 12px;
  vertical-align: middle;
  box-shadow: inset 0 -1px 0 0 #eceff7;
  border: none;
}
.table-style tbody tr td {
  line-height: 16px;
  padding: 16px 12px;
  font-size: 14px;
  color: #58627a;
  vertical-align: middle;
  box-shadow: inset 0 -1px 0 0 #eceff7;
  border: none;
  border-left: 1px solid #eceff7;
}
.table-style tbody tr td:first-child {
  border-left: none;
}
.table-style tbody tr th {
  box-shadow: inset 0 -1px 0 0 #eceff7;
  border: none;
  font-size: 14px;
  color: #232a39;
  padding: 16px 12px;
  line-height: 16px;
  vertical-align: middle;
}

.table-style tbody tr td.bl {
  border-left: 1px solid #eceff7;
}
::ng-deep .filter-canvas .daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #68a2b9 !important;
  border-color: transparent;
  color: #fff;
  border-radius: 50%;
}
.filter-canvas-input {
  border: 2px solid #e1e5ee !important;
}
.filter-canvas {
  width: 360px;
  background-color: #ffffff;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  position: fixed;
  right: 0px;
  top: 0px;
  height: 100%;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 111;
}
.filter-canvas .head {
  padding: 16px 30px;
  position: relative;
}
.filter-canvas .head h3 {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: #232a39;
}

.filter-canvas .head .btn-close {
  position: absolute;
  right: 16px;
  top: 16px;
  padding: 4px;
  z-index: 1;
}
.filter-canvas .body {
  padding: 16px 30px;
  position: relative;
  height: calc(100vh - 120px);
  overflow-y: auto;
}
.filter-canvas .body .form-label {
  display: block;
  font-size: 12px;
  line-height: 12px;
  color: #8a93a7;
  margin-bottom: 8px;
}
.filter-canvas .body .form-group {
  display: block;
  margin-bottom: 16px;
}
.filter-canvas .body .form-group .form-select {
  border: 1px solid #e1e5ee;
  border-radius: 4px;
  font-size: 14px;
  color: #adb6c6;
  line-height: 20px;
  padding: 14px 16px;
}
.filter-canvas .body .form-group .form-control {
  padding: 12px 16px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
}
.filter-canvas .foot {
  padding: 12px 30px;
  position: relative;
  box-shadow: inset 0 1px 0 0 #e7e9eb;
}
.filter-canvas .foot .btn {
  height: 36px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  padding: 9px 30px;
  border: none;
}
.filter-canvas .foot .btn.btn-clear {
  padding: 9px 0px;
  text-decoration: none;
  color: #232a39;
}

.ar-table-thead-row {
  background-color: #8a93a7;
}
.ar-table,
.ar-table-row,
.ar-table-col {
  border: 1px solid #8a93a7;
}

.ar-table {
  width: 100%;
  border: none;
}

.ar-table .ar-table-thead-row {
  background-color: #f7f9fc;
  box-shadow: inset 0 -1px 0 0 #eceff7;
}

.ar-table .ar-table-thead-row th {
  color: #8a93a7;
  font-family: Inter;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
}

.ar-table .ar-table-row td {
  border: 1px solid #eceff7;
  font-size: 14px;
  color: #232a39;
  padding: 16px 12px;
  line-height: 16px;
  vertical-align: middle;
}

.ar-table,
.ar-table tbody .ar-table-row {
  border: none;
}

.ar-table .ar-table-thead .ar-table-thead-header {
  background-color: #f7f9fc;
  font-size: 14px;
  color: #8a93a7;
  line-height: 16px;
  font-weight: normal;
  padding: 12px 12px;
  vertical-align: middle;
  border: none;
}

.ar-table tbody > .ar-table-row > td:first-of-type {
  border-left: none;
}

.ar-table .ar-table-row td:last-of-type {
  border-right: none;
}
.profile-list {
  position: fixed;
  width: 270px;
  left: 64px;
  top: 0px;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 1px 0 0 0 #eceff7;
}
.data-list {
  position: fixed;
  width: 300px;
  left: 60px;
  top: 0px;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 1px 0 0 0 #eceff7;
}
.data-list .data-heading {
  height: 60px;
  width: 100%;
  background-color: #ffffff;
  box-shadow: inset 0 -1px 0 0 #eceff7;
  position: relative;
}
.data-list .data-heading .btn {
  position: absolute;
  top: 12px;
  right: 30px;
  height: 36px;
  width: 40px;

  border-radius: 4px;
  background-color: #ffffff;
  text-align: center;
  color: #ccc;
  padding: 0px;
}
.data-heading .btn .btn-light:focus {
  border: 1px solid #68a2b9 !important;
  border-radius: 4px;
  background-color: #eff5f8;
}
.btn-light:hover {
  color: #000;
  background-color: #f9fafb;
  border-color: #eceff7 !important;
}
.data-list .data-heading h3 {
  color: #232a39;
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  padding: 14px 30px;
}

.list {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.list .list-item {
  padding: 20px;
  box-shadow: inset 0 -1px 0 0 #eceff7;
  position: relative;
}
.list .list-item:hover {
  background-color: #f5f7fe;
  cursor: pointer;
}
.list .list-item.active {
  background-color: #f5f7fe;
}
.list .list-item .list-img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: inline-block;
  background-color: #f0f3f8;
  text-align: center;
  font-size: 18px;
  line-height: 40px;
  margin-right: 16px;
  vertical-align: middle;
  color: #ccc;
  position: relative;
}
.list .list-item .list-img .dot {
  height: 12px;
  width: 12px;
  border: 2px solid #ffffff;
  background-color: #12af6f;
  border-radius: 50%;
  display: block;
  position: absolute;
  right: 0px;
  bottom: 0px;
}
.list .list-item .list-text {
  display: inline-block;
  vertical-align: middle;
}
.list .list-item .list-text h3 {
  font-size: 16px;
  font-weight: 500;
  color: #232a39;
  font-family: Inter;
  line-height: 20px;
  margin-bottom: 5px;
}
.list .list-item .list-text p {
  color: #8a93a7;
  font-family: Inter;
  font-size: 12px;
  line-height: 15px;
  margin: 0px;
}
.list .list-item .list-menu {
  position: absolute;
  right: 16px;
  top: 20px;
}
.list .list-item .list-menu .btn {
  padding: 0px;
  height: 24px;
  width: 24px;
  color: #ccc;
  font-size: 18px;
}

.x-tabs {
  position: relative;
  min-height: 100vh;
}
.order-tab {
  min-height: 96vh !important;
}
.x-tabs .nav-tabs {
  background-color: #ffffff;
  box-shadow: inset 0 -1px 0 0 #eceff7;
  padding: 0px 30px;
  border: none;
  z-index: 111;
  position: sticky;
}
.x-tabs .nav-tabs .nav-item {
  margin-right: 40px;
}
.x-tabs .nav-tabs .nav-link {
  margin: 0px;
  border: none;
  border-radius: 0px;
  background: none;
  color: #8a93a7;
  font-family: Inter;
  font-size: 16px;
  line-height: 20px;
  position: relative;
  padding: 20px 0px;
}
.x-tabs .nav-tabs .nav-link.active {
  color: #68a2b9;
  font-weight: bold;
}
.x-tabs .nav-tabs .nav-link.active:after {
  position: absolute;
  content: "";
  display: block;
  height: 2px;
  width: 100%;
  bottom: 1px;
  left: 0px;
  background-color: #68a2b9;
}
.x-tabs .tab-content {
  padding: 30px;
}

.card-chart-info {
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  min-height: 110px;
  border: none;
  padding: 20px 30px;
  margin-bottom: 30px;
  height: 90%;
  position: relative;
}
.card-chart {
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  min-height: 110px;
  border: none;
  padding: 20px 30px;
  margin-bottom: 30px;
  position: relative;
}
.card-chart .card-body,
.card-chart-info .card-body {
  padding: 0px;
}
.card-chart .card-head,
.card-chart-info .card-head {
  margin-bottom: 24px;
}
.card-chart .card-head h3,
.card-chart-info .card-head h3 {
  color: #232a39;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin: 0px;
}

.custom-flexbox {
  flex-direction: row;
  align-content: center;
}

/* Pagination */
.paginationBttns {
  flex-basis: 33%;
  padding: 0;
  display: flex;
  list-style-type: none;
  flex-direction: row;
  justify-content: center;
}

.custom-form-select {
  flex-basis: 20%;
}

.paginationBttns a {
  text-decoration: auto;
  border-radius: 4px;
  color: #1d1b1b;
  cursor: pointer;
  vertical-align: middle;
}

.paginationBttns a:hover {
  /* color: white !important;
  background-color: #273a50;
  border: none; */
}

.paginationActive a {
  color: white !important;
}
.paginationActive {
  background-color: #68a2b9;
  border-radius: 50%;
  height: 31px;
  width: 32px;
}
.paginationDisabled a {
  color: rgb(162 148 148);

  text-decoration: auto;
}
.paginationBttns li {
  margin-right: 19px;
  text-align: center;
  line-height: 30px;
  font-size: 14px;
  color: #58627a;
}
.previousBttn,
.nextBttn {
  font-size: 19px;
  color: #8a93a7 !important;
}

.pagination-items-count {
  float: right;
  color: #58627a;
  font-size: 14px;
  margin-top: 10px;
}
.pagination-row {
  margin-top: 19px;
}
.pagination-select {
  width: 110px;
  margin-left: 13px;
}
.pagination-select .css-yk16xz-control {
  border: 2px solid #eceff7 !important;
  height: 33px !important;
}
.dot3 {
  height: 10px;
  width: 10px;
  background-color: blue;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.dot1 {
  height: 10px;
  width: 10px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.dot2 {
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.my-chart {
  height: 128px !important;
  width: 128px !important;
  margin-top: 23px;
}

.rectangle {
  position: absolute;
  width: 200px;
  bottom: 100%;
  left: 24%;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 8px 24px 0 rgb(53 64 90 / 24%);
  padding: 15px 0px;
}

.powerby {
  transform: translate(-30px, -100%);
  font-size: 14px;
}

.custom-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1;
  width: 100%;
}
.custom-eye {
  position: absolute;
  font-size: 20px;
  line-height: 1;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
}

.custom-dotted {
  overflow: hidden;
  display: block;
  white-space: nowrap;
  width: 122px;
  text-overflow: ellipsis;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke-linecap: unset !important;
  stroke: #68a2b9 !important;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #68a2b9 !important;
  font-weight: 500;
  font-size: 17px !important;
}
.graph-info .loaded {
  height: 8px;
  width: 8px;
  background-color: #68a2b9;
  border-radius: 2px;
  margin-right: 13px;
  margin-top: 5px;
}

.graph-info .remaining {
  height: 8px;
  width: 8px;
  background-color: #dce0e7;
  border-radius: 2px;
  margin-right: 13px;
  margin-top: 5px;
}
.graph-info .total {
  height: 8px;
  width: 8px;
  background-color: #232a39;
  border-radius: 2px;
  margin-right: 13px;
  margin-top: 5px;
}
.graph-info {
  display: flex;
  margin-bottom: 20px;
}

.css-1okebmr-indicatorSeparator {
  display: none;
}
.css-1wa3eu0-placeholder {
  color: #adb6c6;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  padding: 4px;
}
.css-26l3qy-menu {
  border: none;
  padding: 8px;
  font-size: 14px;
  border-radius: 4px;
  box-shadow: 0 8px 24px 0 rgba(53, 64, 90, 0.24) !important;
}
.daterangepicker {
  border: none !important;
  box-shadow: 0 8px 24px 0 rgb(53 64 90 / 24%) !important;
  position: fixed !important;
  top: 139px !important;
}
.daterangepicker td.active,
.daterangepicker td.active:hover,
.today.active.start-date.active.end-date available {
  background-color: #68a2b9 !important;
  height: 30px !important;
  width: 14px !important;
  color: #fff !important;
  border-radius: 50% !important;
}
.daterangepicker .ranges li {
  border-radius: 4px 0px 0 4px !important;
}
.daterangepicker .ranges li.active {
  background-color: #eff5f8 !important;
  color: #68a2b9 !important;
  border-radius: 4px 0px 0 4px !important;
}
.daterangepicker.opensright:after {
  display: contents !important;
  top: 1px !important;
}
.daterangepicker:before {
  border-right: none !important;
  border-left: none !important;
}

.daterangepicker .calendar-table .next span,
.daterangepicker .calendar-table .prev span {
  border: solid #aa9d9d !important;
  border-width: 0px 1px 1px 0 !important;
  padding: 5px !important;
}

.daterangepicker .ranges ul {
  margin-left: 19px !important;
}

.daterangepicker .calendar-table th {
  font-weight: 500 !important;
}
.daterangepicker .calendar-table th.month {
  font-weight: 700 !important;
}
.css-1uccc91-singleValue {
  color: #58627a !important;
  font-size: 14px;
  padding: 4px;
}

.po-heading {
  margin: 7px 0px 27px 0px;
  font-weight: 600;
  color: #232a39;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
.po-download,
.ticket-download {
  border-left: none !important;
}
.bi {
  color: #a1a9c0;
}
.css-1rkncke-control:active,
.css-1rkncke-control:focus,
.css-1rkncke-control:hover {
  border: 2px solid #68a2b9 !important;
  border-color: #68a2b9;
}
.css-n8olto-control {
  border: 1px solid #68a2b9 !important;
  border-color: #68a2b9;
}

.profile-section h3 {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: #232a39;
  margin-bottom: 22px;
  margin-top: 25px;
}
.profile-section .form-label {
  display: block;
  font-size: 12px;
  line-height: 12px;
  color: #8a93a7;
  margin-bottom: 8px;
}

.profile-section .profile-input {
  /* border: 2px solid #e1e5ee !important; */
  height: 48px;
}
.address-label {
  margin-top: 20px;
}
.save-profile {
  font-size: 14px;
  margin-top: 29px;
  height: 48px;
  width: 160px;
  border-radius: 4px;
  margin-bottom: 20px;
}
.btn-outline {
  border: 2px solid #68a2b9;
  color: #68a2b9;
  font-weight: 500;
  border-radius: 4px;
  font-size: 14px;
}

.log-out {
  color: #8a93a7;
}

.eEihBq {
  padding: 0px !important;
  margin-bottom: 14px;
}
.is-canvas-checkbox:checked {
  background-color: #68a2b9 !important;
  border-color: #68a2b9 !important;
}
.is-canvas-checkbox:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 -0.75rem rgb(13 110 253 / 25%);
}

.form-check-input:focus {
  box-shadow: 0 0 0 -0.75rem rgb(13 110 253 / 25%);
}
.chart-col {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.form-control:disabled, .form-control[readonly]{

    background-color: #fafbfd;
    opacity: 1;

}

.btn-primary:disabled, .btn-primary.disabled{
  background-color: #f5f7fb !important; border: 1px solid #80808033; 
  position: inherit;
  color: #C2C8D5;
  font-size: 14px;
  font-weight: 600;



}

@media screen and (max-width: 1220px) {
  .my-chart {
    margin-left: -11px;
  }
}

@media screen and (max-width: 1460px) and (min-width: 1200px) {
  .my-chart {
    width: 122px !important;
  }
}

@media screen and (max-width: 580px) {
  .content .container-fluid {
    padding: 0px;
  }
  .title-row {
    margin: 38px 0px 23px 19px;
  }
  .filter-row {
    margin-left: 19px;
  }
  .card-info {
    padding: 16px 2px 15px 16px;
  }
  .card-info .card-icon {
    position: static;
    margin-bottom: 16px;
  }
  .content {
    margin-left: 0px;
  }
  .updated-time {
    display: block;
    margin-top: 8px;
  }
  .search-ticket {
    /* width: 156px; */
  }
  .card-table {
    padding: 0px;
  }
  .card-table .card-head {
    padding: 15px;
    margin-bottom: 0px;
  }
  .card-table .card-head h3 {
    margin: 5px 0px 4px 0px;
  }
  .filter-canvas {
    width: 100%;
  }
  .x-tabs .tab-content {
    padding: 17px;
  }
}

.custom-img-class {
  position: absolute;
  font-size: 20px;
  line-height: 1;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
}
.home-page-table .ar-table-row:nth-child(1) > .ar-table-col:nth-child(1) {
  font-weight: bold !important;
}
/* .css-yk16xz-control {
  height: 48px !important;
  border: 2px solid #e1e5ee !important;
} */

.custom-body-shadow {
  box-shadow: 0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%);
  padding: 20px 30px;
}
.custom-img-class {
  position: absolute;
  font-size: 20px;
  line-height: 1;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
}
.daterangepicker td.off,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date,
.daterangepicker td.off.end-date {
  background-color: transparent !important;
  border-color: transparent;
  color: #999 !important;
}

.ag-header-row {
  color: #8a93a7;
  font-family: Inter;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  background-color: #f7f9fc;
  box-shadow: inset 0 -1px 0 0 #eceff7;
}

.cell-span {
  background-color: #00e5ff;
}

.custom-focus .btn {
  box-shadow: none !important;
}

.hide-error {
  display: none;
}

.show-error {
  text-align: center;
  color: #DB4438;
  display: block;
  font-size: 12px;
}
